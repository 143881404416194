.droppableAreas {
    border: 1px solid #ddd;
    height: calc(100vh - 260px);
    overflow-x: hidden;
    padding: 2px;
}

.draggable-item {
    cursor: pointer;
    background: #fff;
    font-weight: 100;
    padding: 2px 6px;
    border-bottom: 1px solid #eee;
}

.draggable-item:hover {
    background: #c4e3f3;
}

.draggable-item.draggingoveritem {
    border-top: 2px solid #5DB2FF;
}

.draggable-item.dragging {
    background-color: #c4e3f3;
    opacity: 0.4;
}

.draggable-item.selected {
    background: #7ecbf3;
}

.droppable.draggingover {
    background: #f3f3f3;
    border: 1px dashed #ddd;
}

.droppable.draggingover div:last-child {
    border-bottom: 2px solid #5DB2FF;
}

.droppable {
    border: 1px solid #ddd;
    height: calc(100vh - 360px);
    overflow-x: hidden;
    padding: 2px;
}

.draggable-item {
    cursor: pointer;
    background: #fff;
    font-weight: 100;
    padding: 2px 6px;
    border-bottom: 1px solid #eee;
}

.draggable-item:hover {
    background: #c4e3f3;
}

.draggable-item.draggingoveritem {
    border-top: 2px solid #5DB2FF;
}

.draggable-item.dragging {
    background-color: #c4e3f3;
    opacity: 0.4;
}

.draggable-item.selected {
    background: #7ecbf3;
}

.droppable.draggingover {
    background: #f3f3f3;
    border: 1px dashed #ddd;
}

.droppable.draggingover div:last-child {
    border-bottom: 2px solid #5DB2FF;
}