/* BRAND BUTTONS */

.button-brand {
    display: table;
    top: 0;
    left: 0;
    background-color: #dddfeb;
    height: 32px;
    width: 100%;
    border-bottom: 2px solid;
    text-align: right;
    padding-right: 10px;
}

.middle {
    display: table-cell;
    vertical-align: middle;
}

.inner {
    margin-left: auto;
    margin-right: auto;
}

/* BOTONES */

.box-button {
    vertical-align: text-bottom;
    background: none;
    border: 1px solid transparent;
    cursor: pointer;
    font-size: 15px;
    color: #1cc88a;
    transition: color 1s ease;
}

.box-button.contrast {
    color: #ddd !important;
}

.box-button:hover {
    color: rgb(7, 146, 61)
}

.box-button:hover i.fa {
    text-shadow: 0 0 5px rgb(7, 146, 61)
}

.box-button.danger:hover {
    color: rgb(198, 40, 40);
}

.box-button.danger:hover i.fa {
    text-shadow: 0 0 5px rgba(198, 40, 40, 0.6);
}

.box-button.disabled {
    color: #bbb !important;
    cursor: not-allowed;
}

.box-button.disabled:hover {
    color: #bbb !important;
}

.box-button.disabled:hover i.fa {
    text-shadow: none;
}

.box-button.danger.disabled.contrast {
    color: #888 !important;
    cursor: not-allowed;
}

.box-button.disabled.contrast {
    color: #888 !important;
    cursor: not-allowed;
}

.box-button.danger.disabled.contrast:hover i.fa {
    text-shadow: none;
}

.box-button.disabled.contrast:hover i.fa {
    text-shadow: none;
}

/* SELECTOR INPUT */

.SelectorInput {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 2px;
}

.SelectorInput span.caption {
    flex: 1;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.SelectorInput span.caption span {
    width: 100%;
    line-height: 20px;
}

.SelectorInput span.reset {
    width: 14px;
    color: #aaa;
    cursor: pointer;
    line-height: 24px;
    margin-right: 10px;
}

.SelectorInput span.reset:hover {
    color: #777
}

.SelectorInput span.button {
    width: 20px;
    position: relative;
}

.SelectorInput span.button button {
    position: absolute;
    width: 100%;
    height: 20px;
    line-height: 16px;
    border: 1px solid #aaa;
    border-radius: 16px;
    padding: 0 4px;
    margin-left: -4px;
    outline: none;
    font-weight: bold;
}

.SelectorInput span.button button:hover {
    background: #ccc;
}

/*  */

.isDisabled {
    color: currentColor;
    cursor: not-allowed;
    opacity: 0.5;
    text-decoration: none;
}

/*  CONTROLES  */

.form-control-sm1 {
    height: calc(1.7em + 0rem + -5px);
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0;
}

.has-search .form-control {
    padding-left: 2.375rem;
}

.has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
}

.sidenav-fondo {
    height: calc(100vh - 60px);
    width: 100vw;
    position: fixed;
    top: 65px;
    background-color: #7b7c7e78;
    z-index: 200;
    overflow-x: hidden;
    overflow-y: auto;
    right: 0;
}

.sidenav {
    height: calc(100vh - 60px);
    width: 70vw;
    background-color: #ffffff;
    overflow-x: hidden;
    padding-top: 5px;
    overflow-y: auto;
    position: relative;
    float: right;
}

.sidenav-x1 {
    height: calc(100vh - 60px);
    width: 40vw;
    background-color: #ffffff;
    overflow-x: hidden;
    padding-top: 5px;
    overflow-y: auto;
    position: relative;
    float: right;
}

.sidenav-left {
    height: 100%;
    width: 21vw;
    position: fixed;
    z-index: 200;
    top: 65px;
    left: 0;
    background-color: #fbf8f8;
    /* overflow-x: hidden; */
    height: calc(100vh - 60px);
}

.sn-xs {
    width: 35vw !important;
}

@media (max-width: 480px) {
    .sidenav-left {
        width: 46%;
    }
}

.placeholder {
    background: #1cc88a;
    display: block;
}

.placeholder::before {
    content: "Mover aqui...";
    /* color: rgb(225, 210, 90); */
}

.react-grid-Cell--frozen {
    z-index: 0;
}

.css-mdm715 {
    z-index: 1 !important;
}

.disabledDiv {
    pointer-events: none;
    opacity: 0.4;
}

.disabledCell {
    pointer-events: none;
    background-color: #dad8d8;
    opacity: 0.4;
}

/* SPINNER */

.loading-main {
    height: 100%;
    background-color: #bdbdbd59;
    width: 100%;
    position: absolute;
    z-index: 10000;
    top: 0;
    bottom: 0;
}

.textLoading {
    height: auto;
    width: auto;
    position: absolute;
    left: 40%;
    top: 61%;
    font-weight: bold;
    color: #5f8c54;
    font-size: 20PX;
}

@keyframes hide {
    0% {
        opacity: 0;
    }
    10% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}

.totales {
    border-radius: calc(0.35rem - 1px) calc(0.35rem - 1px) 0 0;
    border: 1px solid #e3e6f0;
    padding-bottom: 1rem !important;
    padding-top: 1rem !important;
    display: table;
    top: 0;
    left: 0;
    background-color: #f8f9fc;
    height: 32px;
    width: 100%;
    text-align: right;
    padding-right: 10px;
}

/* TABS */

.nav-tabs-card {
    border-bottom: 1px solid #dddfeb;
    background-color: #dddfeb;
    margin: 0 0 !important;
}

.nav-tabs-component {
    border-bottom: 1px solid #e2e2e2;
    background-color: #e2e2e2;
    margin: 0 0px 0 2px;
    color: #797c80;
}

.nav-tabs-component>.nav-item {
    padding-left: 10px;
}

.nav-tabs .nav-link.active {
    background-color: #fbfbfb;
    border-color: #dee2e6 #dee2e6 transparent;
}

.nav-tabs .nav-link.margin-green {
    border-color: #5eba00 !important;
    color: #5eba00 !important;
    font-weight: 500;
}

.nav-tabs .nav-link.margin-orange {
    border-color: #fd9644 !important;
    color: #fd9644 !important;
    font-weight: 500;
}

.nav-tabs .nav-link.margin-blue {
    border-color: #467fcf !important;
    color: #467fcf !important;
    font-weight: 500;
}

.divider-menu {
    height: 100%;
    border-left: 1px solid #f9f9f9;
}

/* BRAND */

.sidebar-brand {
    text-decoration: none;
    font-size: 0.8rem;
    font-weight: 800;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: .05rem;
    z-index: 1;
    color: #ffffff;
    text-decoration: none;
}

.sidebar-brand-icon i {
    font-size: 2rem;
    color: #ffffff !important;
    text-decoration: none;
}

.sidebar-brand-text {
    color: #ffffff !important;
    text-decoration: none;
}

.rotate-n-15 {
    -webkit-transform: rotate(-15deg);
    transform: rotate(-15deg);
}

/* FLOATING BUTTON */

.float {
    position: fixed;
    width: 60px;
    height: 60px;
    left: 0px;
    color: #FFF;
    border-radius: 0 15px 15px 0;
    text-align: center;
    box-shadow: 3px 3px 5px #999;
    z-index: 1;
}

.my-float {
    margin-top: 22px;
    color: #FFF;
}